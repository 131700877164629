.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: 4rem; */
}

@media (max-width: 768px) {
  .home-container {
    margin-top: 6rem;
  }
}

.home-container img {
  height: 40rem;
  margin-top: 3rem;
  /* width: 50%; */
}

@media (max-width: 768px) {
  .home-container img {
    width: 90%;
    height: 90%;
    margin-top: 3rem;
    /* object-fit: fill; */
  }
}

.home-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  /* background-color: red; */
  background-color: black;
  color: white;
  font-family: "Questrial";
  overflow-x: hidden;
}

div {
  color: white;
}
