.portfolio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  max-width: 100%;
  margin-top: 0.7rem;
  position: relative;
  /* z-index: 1; */

  /* background-color: black; */
}

@media (max-width: 768px) {
  .portfolio-container {
    margin-top: 3rem;
  }
}

.portfolio-paintings-container {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(3, minmax(15rem, 1fr));
  gap: 2rem;
}

@media (max-width: 768px) {
  .portfolio-paintings-container {
    grid-template-columns: repeat(2, minmax(8rem, 1fr));
    gap: 2rem;
  }
}

.portfolio-paintings-container img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.portfolio-individual-container {
  height: 15rem;
  width: 15rem;
  overflow: hidden;
}

.portfolio-individual-container img:hover {
  transform: scale(1.05);
  transition: transform ease-in-out 1s, filter linear 0.5s;
  filter: brightness(90%);
}

@media (max-width: 768px) {
  .portfolio-individual-container {
    height: 8rem;
    width: 8rem;
  }
}

.header-temp {
  margin-bottom: 50px;
}

.full-screen-image-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .full-screen-image-container {
    /* align-items: center; */
    z-index: 3;
  }
}

.full-screen-image-inner-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  max-width: 100vw;
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .full-screen-image-inner-container {
    height: 100%;
    width: 100%;
    margin-top: 0rem;
  }
}

@media (max-width: 768px) {
  #full-image {
    max-width: 90vw;
    max-height: 90vh;
    object-fit: cover;
  }
}

#full-image {
  /* max-height: 80%; */
  max-height: 70vh;
  max-width: 70vw;
}

.category-name {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.fullscreen-painting-title {
  font-size: 1.5rem;
}

.fullscreen-painting-info {
  margin-top: 1rem;
}

.next {
  position: absolute;
  height: 3rem;
  width: 3rem;
  top: 50%;
  right: 10%;
  /* z-index: 1; */
}

.next > img,
.previous > img {
  height: 3rem;
  width: 3rem;
}

.previous {
  position: absolute;
  height: 3rem;
  width: 3rem;
  top: 50%;
  left: 10%;
}

.close {
  position: absolute;
  font-size: 2rem;
  top: 5%;
  right: 25%;
}

@media (max-width: 768px) {
  .close {
    position: absolute;
    /* top: 6%; */
    top: 4%;
    right: 5%;
  }
}
