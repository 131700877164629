.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-title {
  font-size: 2rem;
  margin-top: 1.6rem;
}

.form-container {
  /* margin-top: 7rem; */
}

@media (max-width: 768px) {
  .contact-title {
    margin-top: 4rem;
  }
}

.email {
  font-size: 1.3rem;
  padding: 1rem;
  margin-top: 1rem;
}

.email-link {
  font-size: 1.3rem;
}

/* @media (max-width: 768px) {
  .contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
  }
} */

a,
a:visited {
  color: rgb(131, 205, 234);
}

.contact-title {
  font-size: 2rem;
}
