.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

@media (max-width: 768px) {
  .form-container {
    margin-top: 10rem;
  }
}

.form-container > form {
  display: flex;
  flex-direction: column;
}

.form-container > form > label,
.form-container > form > input,
.form-container > form > textarea {
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
  width: 20rem;
}

.form-container > form > input[type="submit"] {
  width: 20rem;
  margin-top: 0.5rem;
}
